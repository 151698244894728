.banner_main{
    padding-top: 75px;
    background-color: rgb(255, 255, 255);
}

.banner_container{
    margin-top: 60px;
}

.box__container{
    display: grid;
    grid-template-rows: (1, auto);
    justify-content: center;
}

.description{
    text-align: start;
}

.description_text{
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-color: rgb(66,133,244);
    background-image: linear-gradient(to bottom,rgb(66,133,244) 25%,rgb(52,168,83) 75%);
}

.download__modal{
    display: block;
    margin-top: 85px;
    text-align: center;
    background: rgb(52,168,83, 0.4);
    padding: 10px;
    border-radius: 15px;
}

.download__options{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.download__box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
}

.pdf_icon, .docx_icon{
    color: white
}

.pdf__color{
    background: rgb(167, 31, 31, 0.9);
}

.docx__color{
    background: rgb(32, 65, 211, 0.9); 
}
.pdf__cont, .docx__cont{
    color: white;
    padding: 10px 10px;
    border-radius: 13px;
}

.pdf__cont:hover{
    background: rgb(167, 31, 31); 
}

.docx__cont:hover{
    background: rgb(32, 65, 211); 
}

@media screen and (max-width: 768px) {
}