.main_navbar{
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    z-index: 99;
}

.navbar__change{
    background: whitesmoke;
    transition: all 0.5s ease-out;
    box-shadow: 3px 10px 17px #bdbebf;
}

.nav_components{
    display: grid;
    grid-template-columns: repeat(5, auto);
    column-gap: 50px;
    list-style: none;
    margin-right: 10%;
    font-weight: 600;
}

@media screen and (max-width: 768px) {

    .main_navbar > ul {
        display: none;
        color: aqua;
    }

}
