.main__container{
    margin-top: 100px;
    padding: 20px;
}

.working__container{
    background: rgba(4, 18, 212, 0.75);
    padding: 20px;
    border-radius: 30px;
}

.title__text{
    font-size: 4em;
    color: white;
    display: flex;
    align-items: center;
    width: 65%;
}

.title__text::after{
    content: "";
    flex: 1;
    margin-left: 1rem;
    height: 1px;
    background-color: white;
}

.experience__container{
    padding: 20px;
}

.company__text{
    color: rgb(0, 0, 0);
}

.work__duration{
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    color: white;
}

.exp__highlights > ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    color: white;
}

.exp__highlights > ul > li{
    position: relative;
    padding-left: 40px;
    margin-bottom: 10px;
}

.exp__highlights > ul > li::before{
    content: " > ";
    position: absolute;
    left: 10px;
}

.skills__highlight{
    display: flex;
    margin-top: 20px;
    color: white;
}

.skills__highlight > ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.skills__highlight > ul >li{
    background: rgb(66,133,244, 1);
    padding: 5px 10px;
    border-radius: 12px;
    font-weight: 600;
}
