.main__container{
    margin-top: 100px;
    padding: 20px;
}

.projects__container{

    background: rgba(4, 18, 212, 0.75);
    border-radius: 15px;
}

.professional__container{
    padding: 20px;
    color: white;
}

.title__name{
    font-size: 4em;
    width: fit-content;
}

.each__module{
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}

.left__line{
    border-left: 5px solid white;
}

.project__container{
    width: 90%;
    padding: 20px;
}

.project__name{
    position: relative;
    background: black;
    width: fit-content;
    padding: 20px;
    left: -40px;
    top: -10px;
    z-index: 3;
}

.project__content{
    display: grid;
    grid-template-columns: repeat(2, auto);
    
}

.ns__img{
    position: relative;
    z-index: 1;
    top: -50px;
}

.project__details{
    position: relative;
    left: -30px;
    text-align: right;
    width: 100%;
    z-index: 10;
    
}
.description{
    background: rgb(4, 18, 212);
    padding: 20px;
}

.skill__used{
    font-size: 0.8em;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
}

.external__link{
    color: white;
}

.sideProject__container{
    margin-top: 50px;
    color: white;
}

.sd_title{
    margin-top: 50px;
    font-size: 3em;
    display: flex;
    align-items: center;
}

.sd_title::before, .sd_title::after{
    content: "";
    flex: 1;
    height: 1px;
    background-color: white;
}

.github__actions{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.logo_container{
    display: flex;
    justify-content: center;
}

.wrapper_container{
    display: flex;
    padding: 10px;
    gap: 5px;
    justify-content: center;
    background-color: aliceblue;
    margin-bottom: 10px;
    border-radius: 5px;
    opacity: 0.3;
}