.contact__container{
    margin-top: 50px;
    background: rgb(17, 97, 53);
    padding: 20px;
    text-align: center;
    color: white;
}

.contact__title{
    font-size: 3rem;
    font-weight: 700;
}

.short__description{
    margin-top: 10px;
}

.icons__list{
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.referral_section{
    margin-top: 20px;
    display: flex;
}